import React from 'react';
import {
  CSWweltl,
  QSWweltl,
  CSWweSmall,
  QSWweSmall,
  CSUpsLtl,
  QSUpsLtl,
  CSUpsSmall,
  QSUpsSmall,
  CSFedexLtl,
  QSFedexLtl,
  CSFedexSmall,
  QSFedexSmall,
  CSGtzLtl,
  QSGtzLtl,
  CSXpoLtl,
  QSXpoLtl,
  CSRLLTl,
  QSRLLTl,
  CSUnishippersSmall,
  QSUnishippiersSmall,
  CSFreightQuoteLtl,
  QSFreightQuoteLtl,
  CSYRCLtl,
  QSYrcLtl,
  CSEstesLtl,
  QSEstestLtl,
  CSDayRossLtl,
  QSDayRossLtl,
  CSOdflLtl,
  QSOdflLtl,
  CSSaiaLtl,
  QSSaiaLtl,
  CSPurolatorSmall,
  QSPurolatorSmall,
  DaylightLtl,
  QSDayLightLtl,
  CSAbfLtl,
  QSAbfLtl,
  CSSouthEasternLtl,
  QSSouthEasternLtl,
  CSUspsSmall,
  QSUspsSmall,
  CSEchoLtl,
  QSEchoLtl,
  CSTQLLtl,
  QSTQLLtl,
  CSFreightQuoteChrLtl,
  QSFreightQuoteChrLtl,
  CSUpsShipEngineSmall,
  QSUpsShipEngineSmall,
  CSPriorityOneLtl,
  QSPriorityOneLtl,
  CSUnishipperLtl,
  QSUnishipperLtl,
  CSUpsLandCostApi,
  QSUpsLandCostApi,
} from '../components/Pages';

const useLoadComponent = (index) => {
  const connectionSettigsList = [
    <CSWweltl />,
    <CSWweSmall />,
    <CSUpsLtl />,
    <CSUpsSmall />,
    <CSFedexLtl />,
    <CSFedexSmall />,
    <CSGtzLtl />,
    <CSXpoLtl />,
    <CSRLLTl />,
    <CSUnishippersSmall />,
    <CSYRCLtl />,
    <CSFreightQuoteLtl />,
    <CSEstesLtl />,
    <CSDayRossLtl />,
    <CSOdflLtl />,
    <CSSaiaLtl />,
    <CSAbfLtl />,
    <CSSouthEasternLtl />,
    <CSUspsSmall />,
    <CSTQLLtl />,
    <CSEchoLtl />,
    <DaylightLtl />,
    <CSPurolatorSmall />,
    <CSFreightQuoteChrLtl />,
    <CSUpsShipEngineSmall />,
    <CSPriorityOneLtl />,
    <CSUnishipperLtl />,
    <CSUpsLandCostApi />
  ];
  const quoteSettingsList = [
    <QSWweltl />,
    <QSWweSmall />,
    <QSUpsLtl />,
    <QSUpsSmall />,
    <QSFedexLtl />,
    <QSFedexSmall />,
    <QSGtzLtl />,
    <QSXpoLtl />,
    <QSRLLTl />,
    <QSUnishippiersSmall />,
    <QSYrcLtl />,
    <QSFreightQuoteLtl />,
    <QSEstestLtl />,
    <QSDayRossLtl />,
    <QSOdflLtl />,
    <QSSaiaLtl />,
    <QSAbfLtl />,
    <QSSouthEasternLtl />,
    <QSUspsSmall />,
    <QSTQLLtl />,
    <QSEchoLtl />,
    <QSDayLightLtl />,
    <QSPurolatorSmall />,
    <QSFreightQuoteChrLtl />,
    <QSUpsShipEngineSmall />,
    <QSPriorityOneLtl />,
    <QSUnishipperLtl />,
    <QSUpsLandCostApi />,
  ];

  return [connectionSettigsList[+index], quoteSettingsList[+index]];
};

export default useLoadComponent;
