const types = {
	STORE: 'STORE',
	TOKEN: 'TOKEN',
	CARRIER_ID: 'CARRIER_ID',
	ADDON_ID: 'ADDON_ID',
	GET_CONNECTION_SETTINGS: 'GET_CONNECTION_SETTINGS',
	GET_QUOTE_SETTINGS: 'GET_QUOTE_SETTINGS',
	GET_LOCATIONS: 'GET_LOCATIONS',
	SAVE_LOCATION: 'SAVE_LOCATION',
	DELETE_LOCATION: 'DELETE_LOCATION',
	GET_SERVICES: 'GET_SERVICES',
	GET_CARRIERS: 'GET_CARRIERS',
	SAVE_CARRIER_TAB_SETTINGS: 'SAVE_CARRIER_TAB_SETTINGS',
	GET_ADD_TAB_SETTING: 'GET_ADD_TAB_SETTING',
	GET_CARRIER_DETAILS: 'GET_CARRIER_DETAILS',
	SKELETON_LOADING: 'SKELETON_LOADING',
	ALERT_MESSAGE: 'ALERT_MESSAGE',
	GET_EN_CARRIERS: 'GET_EN_CARRIERS',
	FILTER_CARRIERS: 'FILTER_CARRIERS',
	INSTALL_CARRIER: 'INSTALL_CARRIER',
	INSTALL_ADDON: 'INSTALL_ADDON',
	GET_INSTALLED_CARRIERS: 'GET_INSTALLED_CARRIERS',
	CHANGE_CARRIER_STATUS: 'CHANGE_CARRIER_STATUS',
	GET_INSTALLED_CARRIER_PLAN_INFO: 'GET_INSTALLED_CARRIER_PLAN_INFO',
	CHANGE_ADDON_STATUS: 'CHANGE_ADDON_STATUS',
	GET_ADDONS: 'GET_ADDONS',
	GET_GOOGLE_LOCATION_RESPONSE: 'GET_GOOGLE_LOCATION_RESPONSE',
	GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
	GET_ALL_LOGS: 'GET_ALL_LOGS',
	LOGS_PAGINATION: 'LOGS_PAGINATION',
	GET_LOG_DETAIL: 'GET_LOG_DETAIL',
	GET_PRODUCT_DETAIL: 'GET_PRODUCT_DETAIL',
	PRODUCTS_PAGINATION: 'PRODUCTS_PAGINATION',
	FILTER_PRODUCTS: 'FILTER_PRODUCTS',
	UPDATE_PRODUCT_SETTINGS: 'UPDATE_PRODUCT_SETTINGS',
	GET_PLANS_INFO: 'GET_PLANS_INFO',
	GET_CURRENT_PLAN: 'GET_CURRENT_PLAN',
	CONFIRM_MODAL: 'CONFIRM_MODAL',
	RAD_PLANS: 'RAD_PLANS',
	SBS_PLANS: 'SBS_PLANS',
	GET_INSTALLED_ADDONS: 'GET_INSTALLED_ADDONS',
	GET_ADDON_ADDRESS_SETTING: 'GET_ADDON_ADDRESS_SETTING',
	CHANGE_DEFAULT_ADDRESS: 'CHANGE_DEFAULT_ADDRESS',
	CHANGE_ADDON_SUSPEND_STATUS: 'CHANGE_ADDON_SUSPEND_STATUS',
	GET_BOX_SIZES: 'GET_BOX_SIZES',
	ADD_BOX_SIZE: 'ADD_BOX_SIZE',
	UPDATE_BOX_SIZE: 'UPDATE_BOX_SIZE',
	DELETE_BOX_SIZE: 'DELETE_BOX_SIZE',
	SET_ACTIVE_MENU: 'SET_ACTIVE_MENU',
	GET_ORDERS: 'GET_ORDERS',
	SEARCHED_PRODUCT: 'SEARCHED_PRODUCT',
	SEARCHED_ORDER: 'SEARCHED_ORDER',
	ORDERS_PAGINATION: 'ORDERS_PAGINATION',
	FILTER_ORDERS: 'FILTER_ORDERS',
	GET_ORDER_WIDGET: 'GET_ORDER_WIDGET',
	GET_PACKAGE_DETAIL: 'GET_PACKAGE_DETAIL',
	UPDATE_ORDER_SETTINGS: 'UPDATE_ORDER_SETTINGS',
	IMPORT_INDEXES: 'IMPORT_INDEXES',
	DOWNLOAD_LINK: 'DOWNLOAD_LINK',
	GET_PLANS: 'GET_PLANS',
	SET_CARRIER_TYPE: 'SET_CARRIER_TYPE',
	SET_GTZ_CARRIERS: 'SET_GTZ_CARRIERS',
	SET_INSURANCE_STATUS: 'SET_INSURANCE_STATUS',
	GET_PRODUCT_BOX_SIZES: 'GET_PRODUCT_BOX_SIZES',
	ADD_PRODUCT_BOX_SIZE: 'ADD_PRODUCT_BOX_SIZE',
	UPDATE_PRODUCT_BOX_SIZE: 'UPDATE_PRODUCT_BOX_SIZE',
	DELETE_PRODUCT_BOX_SIZE: 'DELETE_PRODUCT_BOX_SIZE',
	SET_FEDEX_SMALL_CARRIER: 'SET_FEDEX_SMALL_CARRIER',
	GET_SHIPPING_GROUPS: 'GET_SHIPPING_GROUPS',
	GET_PAYMENTS: 'GET_PAYMENTS',
	ADD_SHIPPING_GROUP: 'ADD_SHIPPING_GROUP',
	DELETE_SHIPPING_GROUP: 'DELETE_SHIPPING_GROUP',
	UPDATE_SHIPPING_GROUP: 'UPDATE_SHIPPING_GROUP',
	GET_SHIPPING_RULES: 'GET_SHIPPING_RULES',
	GET_STATES_PROVINCES: 'GET_STATES_PROVINCES',
	GET_STORE_BRANDS: 'GET_STORE_BRANDS',
	GET_STORE_CATEGORIES: 'GET_STORE_CATEGORIES',
	ADD_SHIPPING_RULE: 'ADD_SHIPPING_RULE',
	DELETE_SHIPPING_RULE: 'DELETE_SHIPPING_RULE',
	UPDATE_SHIPPING_RULE: 'UPDATE_SHIPPING_RULE',
	GET_FDO_COUPON_INFO: 'GET_FDO_COUPON_INFO',
	GET_FDO_COUPON_CARRIER_INFO: 'GET_FDO_COUPON_CARRIER_INFO',
	SET_USPS_SMALL_CARRIER: 'SET_USPS_SMALL_CARRIER',
	SET_UPS_SMALL_CARRIER: 'SET_UPS_SMALL_CARRIER',
	/* Dbsc types */
	GET_DBSC_PROFILES: 'GET_DBSC_PROFILES',
	ADD_DBSC_PROFILE: 'ADD_DBSC_PROFILE',
	UPDATE_DBSC_PROFILE: 'UPDATE_DBSC_PROFILE',
	DELETE_DBSC_PROFILE: 'DELETE_DBSC_PROFILE',
	GET_DBSC_CLASSES: 'GET_DBSC_CLASSES',
	ADD_DBSC_CLASS: 'ADD_DBSC_CLASS',
	UPDATE_DBSC_CLASS: 'UPDATE_DBSC_CLASS',
	DELETE_DBSC_CLASS: 'DELETE_DBSC_CLASS',
	GET_DBSC_ORIGINS: 'GET_DBSC_ORIGINS',
	ADD_DBSC_ORIGIN: 'ADD_DBSC_ORIGIN',
	UPDATE_DBSC_ORIGIN: 'UPDATE_DBSC_ORIGIN',
	DELETE_DBSC_ORIGIN: 'DELETE_DBSC_ORIGIN',
	GET_DBSC_ZONES: 'GET_DBSC_ZONES',
	ADD_DBSC_ZONE: 'ADD_DBSC_ZONE',
	UPDATE_DBSC_ZONE: 'UPDATE_DBSC_ZONE',
	DELETE_DBSC_ZONE: 'DELETE_DBSC_ZONE',
	GET_DBSC_RATES: 'GET_DBSC_RATES',
	ADD_DBSC_RATE: 'ADD_DBSC_RATE',
	UPDATE_DBSC_RATE: 'UPDATE_DBSC_RATE',
	DELETE_DBSC_RATE: 'DELETE_DBSC_RATE',
	SET_MODAL_DATA: 'SET_MODAL_DATA',
	GET_DBSC_BC_ZONES: 'GET_DBSC_BC_ZONES',
	GET_DBSC_OTHER_SETTINGS: 'GET_DBSC_OTHER_SETTINGS',
	SET_DBSC_OTHER_SETTINGS: 'SET_DBSC_OTHER_SETTINGS',
	SET_ACTION_BUTTONS_VISIBILITY: 'SET_ACTION_BUTTONS_VISIBILITY',
	SET_DAYROSS_API_TYPE: 'SET_DAYROSS_API_TYPE',
	SET_UPS_SMALL_API_TYPE: 'SET_UPS_SMALL_API_TYPE',
	SET_WWE_SMALL_API_TYPE: 'SET_WWE_SMALL_API_TYPE',
	SET_UPS_LTL_API_TYPE: 'SET_UPS_LTL_API_TYPE',
	SET_WWE_LTL_API_TYPE: 'SET_WWE_LTL_API_TYPE',
	SET_FEDEX_SMALL_API_TYPE: 'SET_FEDEX_SMALL_API_TYPE',
	SET_FEDEX_LTL_API_TYPE: 'SET_FEDEX_LTL_API_TYPE',
	SET_UNISHIPPER_API_TYPE: 'SET_UNISHIPPER_API_TYPE',
	GET_CARRIER_SERVICES: 'GET_CARRIER_SERVICES',
	/* Pallets Packaging types */
	PLT_PLANS: 'PLT_PLANS',

	/* RAD Settings */
	GET_RAD_SETTINGS: 'GET_RAD_SETTINGS',
	SET_RAD_SETTINGS: 'SET_RAD_SETTINGS',
	/* Weight Threshold Settings */
	GET_THRESHOLD_SETTINGS: 'GET_THRESHOLD_SETTINGS',
	SET_THRESHOLD_SETTINGS: 'SET_THRESHOLD_SETTINGS',
	TOGGLE_THRESHOLD_SETTINGS: 'TOGGLE_THRESHOLD_SETTINGS',
	/* Staff Note Settings */
	GET_STAFFNOTE_SETTINGS: 'GET_STAFFNOTE_SETTINGS',
	SET_STAFFNOTE_SETTINGS: 'SET_STAFFNOTE_SETTINGS',
	TOGGLE_STAFFNOTE_SETTINGS: 'TOGGLE_STAFFNOTE_SETTINGS',
	SET_COMPARE_RATES: 'SET_COMPARE_RATES'
}

export default types
