import axios from 'axios'
import types from '../Stores/types'

export const postData = (data, type, url, token, setVisibleWarehouse = null) => {
	const config = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	}
	return dispatch => {
		dispatch({
			type: 'ALERT_MESSAGE',
			payload: {
				showAlertMessage: true,
				alertMessageType: 'loading',
			},
		})

		const isTestConnection = type === 'GET_CONNECTION_SETTINGS' && data.testType
		Object.keys(data).map(
			elem =>
				(data[elem] =
					typeof data[elem] == 'string' ? data[elem].trim() : data[elem])
		)

		axios
			.post(`${process.env.REACT_APP_ENITURE_API_URL}/${url}`, data, config)
			.then(({ data }) => {
				if (!data.error) {
					if (data?.data?.value) {
						dispatch({
							type: type,
							payload: JSON.parse(data?.data?.value),
						})

						if (
							url === 'submit_connection_settings' &&
							data?.data['fdoCouponCarrierInfo'] !== undefined
						) {
							dispatch({
								type: 'GET_FDO_COUPON_CARRIER_INFO',
								payload: data?.data?.fdoCouponCarrierInfo,
							})
						}
					} else if (data?.data && !isTestConnection) {
						dispatch({
							type: type,
							payload: data?.data,
						})
					}

					if (type === 'SAVE_LOCATION') {
						setVisibleWarehouse(false)
					}
				}

				if(url !== 'submit_threshold_settings' && url !== 'submit_staffnote_settings'){
					dispatch({
						type: 'ALERT_MESSAGE',
						payload: {
							alertMessage: data.message,
							showAlertMessage: true,
							alertMessageType: data.error ? 'error' : 'success',
						},
					})
				}
			})
			.catch(error => {})
	}
}

export const dismissAlert = () => dispatch => {
	dispatch({
		type: 'ALERT_MESSAGE',
		payload: {
			alertMessage: null,
			showAlertMessage: false,
			alertMessageType: null,
		},
	})
}

export const setStore = store => {
	//localStorage.setItem('store', store)
	const config = {
		headers: {
			authorization: `Bearer ${store}`,
		},
	}
	return dispatch => {
		axios
			.get('store', {
				...config,
			})
			.then(({ data }) => {
				if (!data.error) {
					dispatch({
						type: 'STORE',
						payload: data.data,
					})
				}
			})
			.catch(err => {
				console.log(err)
			})
	}
}

export const getPlansInfo = data => {
	const config = {
		headers: {
			authorization: `Bearer ${data.store}`,
		},
	}

	return dispatch => {
		axios
			.get(`${process.env.REACT_APP_ENITURE_API_URL}/get_plans_info`, {
				...config,
				params: { store: 'stores/uann2u' },
			})
			.then(({ data }) => {
				if (!data.error) {
					dispatch({
						type: 'GET_PLANS_INFO',
						payload: JSON.parse(data.data[0].value),
					})
				} else {
					dispatch({
						type: 'ALERT_MESSAGE',
						showAlertMessage: true,
						alertMessage: data.message,
						alertMessageType: 'error',
					})
				}
			})
			.catch(error => {})
	}
}

export const getCurrentPlanInfo = store => {
	const config = {
		headers: {
			authorization: `Bearer ${store}`,
		},
	}

	return dispatch => {
		axios
			.get(
				`${process.env.REACT_APP_ENITURE_API_URL}/get-subscription-details`,
				config
			)
			.then(({ data }) => {
				if (!data.error) {
					dispatch({
						type: 'GET_CURRENT_PLAN',
						payload: data.data,
					})
				} else {
					dispatch({
						type: 'ALERT_MESSAGE',
						showAlertMessage: true,
						alertMessage: data.message,
						alertMessageType: 'error',
					})
				}
			})
			.catch(error => {})
	}
}

export const submitCompareRates = (values, token, setLoading = false, handleClick) => async dispatch => {
	try {
		dispatch({
			type: 'ALERT_MESSAGE',
			payload: {
				showAlertMessage: true,
				alertMessageType: 'loading',
			},
		})

		const config = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		}

		const { data } = await axios.post(
			`${process.env.REACT_APP_ENITURE_API_URL}/get_compare_rates`,
			values,
			config
		)

		if (!data.error) {
			const carrdata = []
			if(data.data.small_package !== []){
				carrdata['small_package'] = data.data.small_package
			}
			if(data.data.ups_ship_engine !== []){
				carrdata['ups_ship_engine'] = data.data.ups_ship_engine
			}
			dispatch({
				type: types.SET_COMPARE_RATES,
				payload: carrdata,
			})
			setLoading(true)
			handleClick()
		}

		dispatch({
			type: 'ALERT_MESSAGE',
			payload: {
				showAlertMessage: true,
				alertMessage: data.message,
				alertMessageType: data.error ? 'error' : 'success',
			},
		})
	} catch (err) {
		dispatch({
			type: 'ALERT_MESSAGE',
			payload: {
				showAlertMessage: false,
				alertMessageType: '',
			},
		})
	}
}
